import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router';
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () =>
      import('./views/Home.vue'),
  },
  {
    path: '/program',
    name: 'program',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('./views/Program.vue'),
  },
  {
    path: '/information',
    name: 'customCategory',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('./views/CustomCategory.vue'),
  },
  {
    path: '/notification',
    name: 'notification',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('./views/Notification.vue'),
  },
  {
    path: '/eventsetting',
    name: 'eventsetting',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('./views/EventSetting.vue'),
  },
  {
    path: '/watchlive',
    name: 'watchlive',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('./views/WatchLive.vue'),
  },
  {
    path: '/eventpage',
    name: 'eventpage',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('./views/EventWebPage.vue'),
  },
  {
    path: '/participants',
    name: 'participants',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('./views/Participants.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
export default router;
